import { render, staticRenderFns } from "./yuangongList.vue?vue&type=template&id=c2843db8"
import script from "./yuangongList.vue?vue&type=script&lang=js"
export * from "./yuangongList.vue?vue&type=script&lang=js"
import style0 from "./yuangongList.vue?vue&type=style&index=0&id=c2843db8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports